// custom typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';
import '@fontsource/dm-sans';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/700.css';
import '@fontsource/dm-mono';
import '@fontsource/dm-mono/500.css';

// normalize CSS across browsers
import './src/css/normalize.css';

import './src/css/@wordpress/block-library/build-style/style.css';
import './src/css/@wordpress/block-library/build-style/theme.css';

// custom CSS styles
import './src/css/style.css';
import './src/css/tailwind.css';

import RootElementWithProviders from './wrap-with-providers';

export const wrapPageElement = RootElementWithProviders;
